.Csv {
  background-color: #282c34;
  text-align: center;
  color: white;
}

.Csv table {
  margin: 0 auto;
  width: 75vw;
  background-color: #282c34;
  color: white;
  border-collapse: collapse;
}

th {
  background-color: #222537;
  border: 3px solid #222537;
}

tr.SFF82D6FF {
  color: #FF82D6FF
}

tr.S6E95FFFF {
  color: #6E95FFFF
}

tr.SF88688FF {
  color: #F88688FF
}

tr:nth-child(even) {
  background-color: #222537;
}

.Csv td {
  padding: 1;
}

a {
  text-decoration: none;
  color: inherit;
}
