.Header {
  text-align: center;
}

.Header-logo {
  height: 10vmin;
  pointer-events: none;
}

.Marble-logo {
  height: 10vmin;
  pointer-events: none;
}

.Pepe-logo {
  height: 9vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Header-logo {
    animation: Header-logo-spin infinite 3s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Marble-logo {
    animation: Marble-logo-spin infinite 8s linear;
  }
}

.Header-header {
  background-color: #282c34;
  /* padding-top: 1vmin; */
  padding: 1vmin 3vmin 0vmin 0vmin;
  min-height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Header-left {
  padding-right: 2vmin;
}
.Header-right {
  padding-left: 2vmin;
}
.Header-link {
  color: #61dafb;
}

@keyframes Header-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Marble-logo-spin {
  0% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(390deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(390deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
